import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Fans" />

		<h2>Fans</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST12 fans front.jpg"
			alt="Jesse Rivest - Fans - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on September 2, 2022.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			This song takes me back to a great summer I had in the south island of New Zealand in 2009.
			Since then, I've always played a (different) acoustic version of this song,
			but not like this! For this single, I had a lot of fun with a new rhythm and aesthetic...
			dare I suggest it has a latin vibe?
		</p>
		<p>
			I hope you dig <span className="italic">Fans</span>;
			please share it if you do!
		</p>
		<h3>Track listing</h3>
		<ol>
			<li>
				Fans (4:07)<br />
				T3063135973 - © BMG Cicada (SESAC) / Pacific Wind Music (SESAC) / Songs of Combustion Music 2007<br />
				CA-6P8-22-00004 - © Jesse Rivest 2022 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST12, © Jesse Rivest 2022, All Rights Reserved<br />
			UPC/EAN: 0198001859335<br />
			Released: September 2, 2022
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song sung by Jesse Rivest.<br />
			Acoustic guitar performed by Jesse Rivest.<br />
			Electric guitar performed by Jesse Rivest.<br />
			Backing vocals performed by Jesse Rivest.<br />
			Various percussion performed by Jesse Rivest.<br />
			Drums programmed by Jesse Rivest using Addictive Drums 2.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Tico de Moraes for his ears and feedback on very early versions.<br />
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST12 fans back.png"
			alt="Jesse Rivest - Fans - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
